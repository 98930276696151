import { isNotNil } from '@soltalabs/ramda-extra'

class VendMessenger {
  constructor(domain) {
    this.domain = domain
  }

  setDomain(domain) {
    this.domain = domain
  }

  onMessage(callback) {
    const handleMessage = (event) => {
      if (event.origin !== this.domain) {
        return
      }

      callback(event)
    }

    window.addEventListener('message', handleMessage)
  }

  send(message) {
    const recipient = isNotNil(window.opener) ? window.opener : window.parent

    recipient.postMessage(JSON.stringify(message), this.domain)
  }
}

export { VendMessenger }
