import { http } from 'lib/httpClient'
import { CONFIG } from 'config'
import { isNilOrEmpty } from '@soltalabs/ramda-extra'
import { nanoid } from 'nanoid'
import { CALLBACK_ERROR } from './constants'
import sessionstorage from 'sessionstorage'

const service = http.extend({
  prefixUrl: CONFIG.CONNECTOR_API_URL,
})

class OAuthError extends Error {
  constructor({ message, code }) {
    super(`OAuthError: ${message}`)
    this.code = code
  }
}

const storage = (function () {
  const uid = new Date()

  let isLocalStorageAvailable = true

  try {
    localStorage.setItem(uid, uid)
    localStorage.removeItem(uid)
  } catch (err) {
    isLocalStorageAvailable = false
  }

  return isLocalStorageAvailable ? localStorage : sessionstorage
})()

const AuthService = {
  requestAuthorizationCodeWithRedirect({
    clientId,
    redirectUrl,
    resumePath,
    errorPath,
  }) {
    const url = new URL('connect', 'https://secure.vendhq.com')
    const setParam = url.searchParams.set.bind(url.searchParams)
    const state = nanoid()

    setParam('response_type', 'code')
    setParam('client_id', clientId)
    setParam('redirect_uri', redirectUrl)
    setParam('state', state)

    storage.setItem('oauth.lastKnownState', state)
    storage.setItem(
      `oauth.${state}`,
      JSON.stringify({
        clientId,
        redirectUrl,
        resumePath,
        errorPath,
      })
    )

    window.location.replace(url)
  },

  isValidAuthorizationResponse() {
    const searchParams = new URLSearchParams(window.location.search)
    const getParam = searchParams.get.bind(searchParams)

    const lastKnownState = storage.getItem('oauth.lastKnownState')
    const state = getParam('state')
    const isValid = lastKnownState === state

    if (!isValid) {
      this.clearOAuthLocalStorage()
    }

    return isValid
  },

  clearOAuthLocalStorage() {
    const lastKnownState = storage.getItem('oauth.lastKnownState')

    storage.removeItem('oauth.lastKnownState')
    storage.removeItem(`oauth.${lastKnownState}`)
  },

  isAuthorizationGranted() {
    const searchParams = new URLSearchParams(window.location.search)
    const getParam = searchParams.get.bind(searchParams)

    const isGranted = getParam('error') !== 'access_denied'

    if (!isGranted) {
      const lastKnownState = storage.getItem('oauth.lastKnownState')
      const { errorPath } = JSON.parse(storage.getItem(`oauth.${lastKnownState}`))

      this.clearOAuthLocalStorage()

      return { isGranted, errorPath }
    }

    return { isGranted }
  },

  parseAuthorizationCallback() {
    const searchParams = new URLSearchParams(window.location.search)
    const getParam = searchParams.get.bind(searchParams)

    const requiredParams = ['code', 'domain_prefix', 'state']

    for (const key of requiredParams) {
      if (isNilOrEmpty(getParam(key))) {
        throw new OAuthError({
          code: CALLBACK_ERROR.INVALID_PARAM,
          message: `OAuth: missing required param in authorization callback: ${key}`,
        })
      }
    }

    try {
      const lastKnownState = storage.getItem('oauth.lastKnownState')
      const sessionState = storage.getItem(`oauth.${lastKnownState}`)
      storage.removeItem('oauth.lastKnownState')
      storage.removeItem(`oauth.${lastKnownState}`)

      return {
        authorizationCode: getParam('code'),
        domainPrefix: getParam('domain_prefix'),
        ...JSON.parse(sessionState),
      }
    } catch (error) {
      throw new OAuthError({
        code: CALLBACK_ERROR.STATE_MISMATCH,
        message:
          'Failed to hydrate session state from local storage.' +
          'The last known state nonce is incorrectly persisted.',
      })
    }
  },

  async requestAccessToken({ domainPrefix, clientId, redirectUrl, authorizationCode }) {
    const payload = {
      domainPrefix,
      clientId,
      redirectUri: redirectUrl,
      code: authorizationCode,
    }

    const config = {
      json: payload,
    }

    return service.post('oauth/access-token', config).json()
  },
}

export { AuthService }
