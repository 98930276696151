import './globals'
import React from 'react'
import ReactDOM from 'react-dom'
import { GatewayProvider } from 'GatewayContext'
import GatewayProxy from './App'
import { Router } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'

const history = createHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <GatewayProvider>
        <GatewayProxy />
      </GatewayProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
