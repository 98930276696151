import 'styles/index.scss'

import React, { Suspense, useLayoutEffect } from 'react'
import { InvalidUrlPage } from 'components/pages/InvalidUrl'
import { getGateway } from 'utils/getGateway'
import { useGatewayContext } from 'GatewayContext'
import { routes } from 'routes'
import { isNil } from '@soltalabs/ramda-extra'
import { Loader } from 'components/Loader'

const gatewayManifest = Object.entries(routes).reduce((acc, [gatewayId]) => {
  acc[gatewayId] = React.lazy(() => import(`gateways/${gatewayId}`))
  return acc
}, {})

function GatewayProxy() {
  const { gatewayId, setGatewayId } = useGatewayContext()
  const GatewayImplementation = gatewayManifest[gatewayId]

  useLayoutEffect(() => {
    setGatewayId(getGateway())
  }, [setGatewayId])

  if (isNil(GatewayImplementation)) {
    return <InvalidUrlPage />
  }

  return (
    <Suspense fallback={<Loader />}>
      <GatewayImplementation />
    </Suspense>
  )
}

export default GatewayProxy
