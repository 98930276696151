function getSearchParams(searchUrl) {
  const searchParams = new URLSearchParams(searchUrl)

  const transformedSearchParams = {}

  searchParams.forEach((value, key) => {
    transformedSearchParams[key] = value
  })

  return transformedSearchParams
}

export { getSearchParams }
