import React from 'react'
import { isDev } from 'utils'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex flex-column items-center justify-center'))
const Title = styled.h1()
const Body = styled.p()

function InvalidUrlPage() {
  return (
    <Root>
      <Title>Invalid Route</Title>
      <Body>
        {isDev()
          ? "Double check your path to make sure you're requesting a valid endpoint."
          : 'Something has gone wrong. Please try again in a little bit!'}
      </Body>
    </Root>
  )
}

export { InvalidUrlPage }
