import { defaultTo, pipe, split, prop, replace, trim } from '@soltalabs/ramda-extra'

function getGateway() {
  const pathName = window.location.pathname

  const gatewayId = pipe(replace(/\//g, ' '), trim, split(' '), prop(0))(pathName)

  return defaultTo(undefined)(gatewayId)
}

export { getGateway }
