import 'styles/index.scss'

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CONFIG } from 'config'
import { CallbackPage } from 'components/pages/Callback'
import GatewayProxy from './gateways/GatewayProxy'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex flex-column h-full justify-center items-center'))

function App() {
  return (
    <Container>
      <Switch>
        <Route path={CONFIG.PATHS.CALLBACK} component={CallbackPage} />

        <Route path="/" component={GatewayProxy} />
      </Switch>
    </Container>
  )
}

export default App
