import { getEnvVariable } from 'utils/getEnvVariable'

const CONFIG = {
  APP_ENV: getEnvVariable('APP_ENV'),
  CONNECTOR_API_URL: getEnvVariable('CONNECTOR_API_URL'),
  CORS_PROXY_URL: getEnvVariable('CORS_PROXY_URL'),
  VEND_API_URL: getEnvVariable('VEND_API_URL'),
  PAYMENT_TYPE_ID_PARAM: getEnvVariable('PAYMENT_TYPE_ID_PARAM'),
  VEND_API_OAUTH_CLIENT_ID: getEnvVariable('VEND_API_OAUTH_CLIENT_ID'),
  MAPBOX: {
    API_URL: getEnvVariable('MAPBOX_API_URL'),
    ACCESS_TOKEN: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
  },
  PATHS: {
    CALLBACK: getEnvVariable('CALLBACK_BASE_PATH'),
  },
  PAIRING_ROUTE: getEnvVariable('PAIRING_ROUTE'),
  TRANSACTION_ROUTE: getEnvVariable('TRANSACTION_ROUTE'),
  BARCLAYS_GATEWAY_ID: getEnvVariable('BARCLAYS_GATEWAY_ID'),
  BOFA_GATEWAY_ID: getEnvVariable('BOFA_GATEWAY_ID'),
  PAYPAL_GATEWAY_ID: getEnvVariable('PAYPAL_GATEWAY_ID'),
}

window.CONFIG = CONFIG

export { CONFIG }
