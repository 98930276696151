import 'whatwg-fetch'
import ky from 'ky'

const http = ky.create({
  headers: {
    'content-type': 'application/json',
  },
})

const { HTTPError } = ky

function isHttpError(err) {
  return err instanceof ky.HTTPError
}

export { http, HTTPError, isHttpError }
