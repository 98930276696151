function parseBoolean(string) {
  if (string === 'true') {
    return true
  }

  if (string === 'false') {
    return false
  }

  return undefined
}

export { parseBoolean }
