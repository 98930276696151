import { select } from '@soltalabs/stateless'
import { AuthModule } from './module'
import { pathOr, path, prop, isNotNilOrEmpty } from '@soltalabs/ramda-extra'

const { state$, effectState$ } = AuthModule

const isParsingAuthorizationCallback$ = select(effectState$, (effectState) => {
  const status = pathOr('pending')(['parseAuthorizationCallback', 'status'])(
    effectState
  )

  return status === 'pending'
})

const parseAuthorizationCallbackErrorMessage$ = select(
  effectState$,
  path(['parseAuthorizationCallback', 'error'])
)

const requestAccessTokenErrorMessage$ = select(
  effectState$,
  path(['requestAccessToken', 'error'])
)

const accessToken$ = select(state$, prop('accessToken'))
const domainPrefix$ = select(state$, prop('domainPrefix'))
const hasAccessToken$ = select(accessToken$, isNotNilOrEmpty)

export {
  isParsingAuthorizationCallback$,
  parseAuthorizationCallbackErrorMessage$,
  requestAccessTokenErrorMessage$,
  accessToken$,
  domainPrefix$,
  hasAccessToken$,
}
