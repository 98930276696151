import React, { useState, useEffect } from 'react'
import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { AuthModule, requestAccessTokenErrorMessage$ } from 'modules/auth'
import { Redirect } from 'react-router-dom'

import { styled, s } from 'lib/styled'
import { Loader } from 'components/Loader'

const Root = styled.div(s('flex flex-column h-full items-center justify-center'))
const Title = styled.h1()
const Paragraph = styled.p(s('max-w-32 text-center'))

const Connected = connect(() => ({
  requestAccessTokenErrorMessage: requestAccessTokenErrorMessage$,
}))(CallbackPage)

function CallbackPage({ requestAccessTokenErrorMessage }) {
  const [redirectTo, setRedirectTo] = useState()
  const [hasProcessedCallback, setHasProcessedCallback] = useState(false)
  const [isRequestingAccessToken, setIsRequestingAccessToken] = useState(false)
  const [errorMessage, setErrorMessage] = useState(requestAccessTokenErrorMessage)

  const isLoading = !hasProcessedCallback || isRequestingAccessToken
  const hasError = isNotNilOrEmpty(errorMessage)

  useEffect(() => {
    setErrorMessage(requestAccessTokenErrorMessage)
  }, [requestAccessTokenErrorMessage])

  useEffect(() => {
    if (!AuthModule.isValidAuthorizationResponse()) {
      setErrorMessage(
        'An unexpected error occurred while processing the connection response from Vend. Try again in a little bit.'
      )
      setHasProcessedCallback(true)
      return
    }

    const authorizationGrant = AuthModule.isAuthorizationGranted()

    if (!authorizationGrant.isGranted) {
      setRedirectTo(authorizationGrant.errorPath)
      setHasProcessedCallback(true)
      return
    }

    setHasProcessedCallback(true)
    setIsRequestingAccessToken(true)

    AuthModule.requestAccessToken().then((resumePath) => {
      setRedirectTo(resumePath)
      setIsRequestingAccessToken(false)
    })
  }, [])

  if (isLoading) {
    return (
      <Root>
        <Loader />
      </Root>
    )
  }

  if (hasError) {
    return (
      <Root>
        <Title>Connect Error</Title>
        <Paragraph>{errorMessage}</Paragraph>
      </Root>
    )
  }

  return <Redirect to={redirectTo} />
}

export { Connected as CallbackPage }
