import React, { useContext, useState, memo } from 'react'

const GatewayContext = React.createContext({})

function useGatewayContext() {
  return useContext(GatewayContext)
}

function GatewayProvider({ children }) {
  const [gatewayId, setGatewayId] = useState()

  return (
    <GatewayContext.Provider value={{ gatewayId, setGatewayId }}>
      {children}
    </GatewayContext.Provider>
  )
}

const MemoizedProvider = memo(GatewayProvider)

export { MemoizedProvider as GatewayProvider, useGatewayContext }
