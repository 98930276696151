import { CONFIG } from 'config'

const routes = {
  [CONFIG.BARCLAYS_GATEWAY_ID]: {
    [CONFIG.PAIRING_ROUTE]: `/${CONFIG.BARCLAYS_GATEWAY_ID}/${CONFIG.PAIRING_ROUTE}`,
    [CONFIG.TRANSACTION_ROUTE]: `/${CONFIG.BARCLAYS_GATEWAY_ID}/${CONFIG.TRANSACTION_ROUTE}`,
  },
  [CONFIG.BOFA_GATEWAY_ID]: {
    [CONFIG.PAIRING_ROUTE]: `/${CONFIG.BOFA_GATEWAY_ID}/${CONFIG.PAIRING_ROUTE}`,
    [CONFIG.TRANSACTION_ROUTE]: `/${CONFIG.BOFA_GATEWAY_ID}/${CONFIG.TRANSACTION_ROUTE}`,
  },
  [CONFIG.PAYPAL_GATEWAY_ID]: {
    [CONFIG.PAIRING_ROUTE]: `/${CONFIG.PAYPAL_GATEWAY_ID}/${CONFIG.PAIRING_ROUTE}`,
    [CONFIG.TRANSACTION_ROUTE]: `/${CONFIG.PAYPAL_GATEWAY_ID}/${CONFIG.TRANSACTION_ROUTE}`,
  },
}

export { routes }
