import { createModule } from '@soltalabs/stateless'
import { AuthService } from './service'
import { pick } from '@soltalabs/ramda-extra'
import jwtService from 'jsonwebtoken'

const INITIAL_STATE = Object.freeze({
  accessToken: undefined,
  refreshToken: undefined,
  tokenType: undefined,
  expires: undefined,
  expiresIn: undefined,

  domainPrefix: undefined,
  authorizationCode: undefined,

  clientId: undefined,
  redirectUrl: undefined,

  authJwt: undefined,
})

const requestAuthorization = () => async (
  _,
  { clientId, redirectUrl, resumePath, errorPath }
) => {
  AuthService.requestAuthorizationCodeWithRedirect({
    clientId,
    redirectUrl,
    resumePath,
    errorPath,
  })
}

const isValidAuthorizationResponse = () => () => {
  return AuthService.isValidAuthorizationResponse()
}

const isAuthorizationGranted = () => () => {
  return AuthService.isAuthorizationGranted()
}

const parseAuthorizationCallback = (module) => () => {
  const {
    authorizationCode,
    domainPrefix,
    clientId,
    redirectUrl,
    resumePath,
    errorPath,
  } = AuthService.parseAuthorizationCallback()

  module.setState({
    authorizationCode,
    domainPrefix,
    clientId,
    redirectUrl,
    errorPath,
  })

  return resumePath
}

const requestAccessToken = (module, helpers) => async (_) => {
  let resumePath

  try {
    resumePath = module.parseAuthorizationCallback()
  } catch (error) {
    helpers.setError(
      _,
      'There was an error with the connection response from Vend. Try again in a little bit. If the issue persists, contact Vend support.'
    )

    return undefined
  }

  try {
    const {
      authJwt,
      accessToken,
      refreshToken,
      tokenType,
      expires,
      expiresIn,
    } = await AuthService.requestAccessToken(
      pick(['clientId', 'redirectUrl', 'domainPrefix', 'authorizationCode'])(
        module.getState()
      )
    ).then(({ authJwt }) => ({ authJwt, ...jwtService.decode(authJwt) }))

    module.setState({
      authJwt,
      accessToken,
      refreshToken,
      tokenType,
      expires,
      expiresIn,
    })

    return resumePath
  } catch (error) {
    helpers.setError(
      _,
      'An error occurred while connecting to your Vend merchant account. Try again in a little bit.'
    )

    return undefined
  }
}

const getAuthorizationHeader = (module) => () => {
  const { tokenType, accessToken } = module.getState()

  return `${tokenType} ${accessToken}`
}

const getDomainPrefix = (module) => () => {
  const { domainPrefix } = module.getState()

  return domainPrefix
}

const getAuthJwt = (module) => () => {
  const { authJwt } = module.getState()

  return authJwt
}

const AuthModule = createModule({
  name: 'auth',
  initialState: INITIAL_STATE,
  decorators: {
    requestAuthorization,
    isValidAuthorizationResponse,
    isAuthorizationGranted,
    parseAuthorizationCallback,
    requestAccessToken,
    getAuthorizationHeader,
    getDomainPrefix,
    getAuthJwt,
  },
})

export { AuthModule }
